import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import AppSnackbar from "./components/Snackbar";

import NavbarLeft from "./components/navbar/left/NavbarLeft";
import NavbarTop from "./components/navbar/top/NavbarTop";

import "@fontsource/ubuntu";
import { Box } from "@mui/system";
import { LicenseInfo } from "@mui/x-license-pro";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { IntercomProps, IntercomProvider } from "react-use-intercom";
import ErrorBoundaryFallback from "./components/ErrorBoundryFallBack";
import HomeNotifications from "./components/HomeNotifications";
import UbicoLoading from "./components/animation/UbicoLoading";
import PaymentIssueDialog from "./components/billing/PaymentIssueDialog";
import SubscriptionCanceledDialog from "./components/billing/SubscriptionCanceledDialog";
import TrialEndDialog from "./components/billing/TrialEndDialog";
import UbicoAppLoading from "./components/custom/AppLoading";
import {
  INTERCOM_APP_ID,
  REACT_APP_ENV,
  REACT_APP_MUI_LICENSE,
  UBICO_DOMAIN,
} from "./constants";
import {
  Account,
  Invite,
  Profile,
  UbicoSubscription,
} from "./constants/data-types";
import { NotFound } from "./loadables";
import ProtectedRoute from "./pages/ProtectedRoute";
import UnprotectedRoute from "./pages/UnprotectedRoute";
import UnsupportedMobileView from "./pages/UnsupportedMobileView";
import InvitationsDialog from "./pages/settings/account/components/InvitationsDialog";
import {
  isCancelled,
  isPaymentDue,
  isTrialEnded,
} from "./pages/settings/account/utils/billing";
import { setAccount } from "./redux/reducers/accountReducer";
import { setAccountUsage } from "./redux/reducers/accountUsageReducer";
import { setAttributes } from "./redux/reducers/attributesReducer";
import { setSubscription } from "./redux/reducers/billingReducer";
import { setOperators } from "./redux/reducers/operatorsReducer";
import { setProfile } from "./redux/reducers/profileReducer";
import {
  setDraftTasksCount,
  setProductivityTasksCount,
} from "./redux/reducers/tasksCountReducer";
import { setTeam } from "./redux/reducers/teamReducer";
import { setTimezones } from "./redux/reducers/timezonesReducer";
import { setWorkspace, setWorkspaces } from "./redux/reducers/workspaceReducer";
import { RootState } from "./redux/store";
import { appRoutes, OVERVIEW_BILLING_ROUTE } from "./routes/appRoutes";
import { authRoutes } from "./routes/authRoutes";
import { Route as UbicoRoute } from "./routes/routeInterface";
import AccountService from "./services/accountService";
import ConditionService from "./services/conditionService";
import DraftTaskService from "./services/draftTaskService";
import InviteService from "./services/inviteService";
import ProductivityTaskService from "./services/productivityTaskService";
import ProfileService from "./services/profileService";
import TimezoneService from "./services/timezoneService";
import WorkspaceService from "./services/workspaceService";
import { isUserAllowed } from "./utils/user-role-utils";

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE);

const renderNestedRoutes = (
  routes: Array<UbicoRoute>,
  profile?: Profile,
): JSX.Element[] => {
  return routes.map((route: UbicoRoute, index: number) => {
    if (route.isDrawer || route.onClick) return;

    // Feature gating for internal testing
    if (
      route?.isInternalTesting &&
      !profile?.user?.email?.includes(UBICO_DOMAIN)
    ) {
      return null;
    }
    if (
      profile?.user_role != undefined &&
      !isUserAllowed(profile?.user_role, route.leastRole)
    ) {
      return null;
    }

    if (route.nestedRoutes) {
      return (
        <Route
          key={index}
          element={
            route.component && (
              <>
                <route.component />
                <Outlet />{" "}
              </>
            )
          }
          path={route.path}
        >
          {renderNestedRoutes(route.nestedRoutes, profile)}
        </Route>
      );
    } else {
      return (
        // End route
        <Route key={index} path={route.path} element={<route.component />} />
      );
    }
  });
};

const App: React.FC = (): React.ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { is_logged_in } = useSelector((state: RootState) => state.auth);
  const profile: Profile = useSelector((state: RootState) => state.profile);
  const { id: workspaceId } =
    useSelector((state: RootState) => state.workspace.current) ?? {};
  const account: Account = useSelector((state: RootState) => state.account);
  const subscription: UbicoSubscription = useSelector(
    (state: RootState) => state.billing.subscription,
  );
  const team: Profile[] = useSelector((state: RootState) => state.team);

  const [invites, setInvites] = useState<Array<Invite>>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const [showTrialEnd, setShowTrialEnd] = useState(false);
  const [showSubscriptionCanceled, setShowSubscriptionCanceled] =
    useState(false);
  const [showPaymentIssue, setShowPaymentIssue] = useState(false);

  const getProfile = async () => {
    const { error, data } = await ProfileService.getProfile();
    if (!error && data) {
      dispatch(setProfile(data));
      localStorage.setItem("profileId", data.id.toString());
    }
  };

  const getAccount = async () => {
    const { error, data } = await AccountService.getAccount();
    if (!error && data) {
      dispatch(setAccount(data));
    }
  };

  const getMyInvites = async () => {
    const { error, data } = await InviteService.getIncomingInvites();
    if (!error && data) {
      setInvites(data);
    }
  };

  const getAccountWorkspaces = async () => {
    const { error, data } = await WorkspaceService.getAccountWorkspaces();
    if (!error && data && data.length > 0) dispatch(setWorkspaces(data));
    if (!workspaceId && data) {
      const defaultWorkspace = data?.find((w) => w.is_default);
      if (defaultWorkspace) dispatch(setWorkspace(defaultWorkspace));
      else dispatch(setWorkspace(data?.[0]));
    }
  };

  const getTeam = async () => {
    const { error, data } = await AccountService.getTeam();
    if (!error && data) dispatch(setTeam(data));
  };

  const getAttributes = async () => {
    const { error, data } = await ConditionService.getAttributes(workspaceId);
    if (!error && data) dispatch(setAttributes(data));
  };

  const getOperators = async () => {
    const { error, data } = await ConditionService.getOperators(workspaceId);
    if (!error && data) dispatch(setOperators(data));
  };

  const getTasksCount = async () => {
    const { error: productivityError, data: productivityData } =
      await ProductivityTaskService.getMyPendingTasksCount(workspaceId);
    if (!productivityError && productivityData !== null)
      dispatch(setProductivityTasksCount(productivityData));

    const { error: draftError, data: draftData } =
      await DraftTaskService.getMyPendingDraftsCount(workspaceId);
    if (!draftError && draftData !== null)
      dispatch(setDraftTasksCount(draftData));
  };

  const getSubscription = async () => {
    const { data, error } = await AccountService.getSubscriptionDetails();
    if (!error && data) dispatch(setSubscription(data));
  };

  const getAccountUsage = async () => {
    const { data, error } = await AccountService.getAccountOverview();
    if (!error && data) dispatch(setAccountUsage(data));
  };

  const getTimezones = async () => {
    const resp = await TimezoneService.getTimezones();
    if (!resp.error && resp.data) dispatch(setTimezones(resp.data));
  };

  const loadInitialData = async () => {
    // Data that does not require Workspace
    await Promise.all([getProfile(), getAccount(), getTeam()]);
    setIsLoadingData(false);
  };

  const loadAppData = async () => {
    await Promise.all([
      getMyInvites(),
      getAttributes(),
      getOperators(),
      getTasksCount(),
      getAccountUsage(),
      getTimezones(),
    ]);
    setIsLoadingData(false);
  };

  const onLoggedIn = async () => {
    // When logged in, we pull data that is used across the app in many components
    await loadInitialData();
    // Data that required Workspace
    if (workspaceId !== null && workspaceId !== undefined) {
      loadAppData();
    }
  };

  useEffect(() => {
    setIsLoadingData(true);
    if (is_logged_in) {
      onLoggedIn();
    } else {
      setIsLoadingData(false);
    }
  }, [is_logged_in, workspaceId]);

  useEffect(() => {
    // Update account workspaces and subscription when account setup is completed
    if (is_logged_in && account.completed_account_setup) {
      getSubscription()
      getAccountWorkspaces();
    }
  }, [is_logged_in, account.completed_account_setup]);

  useEffect(() => {
    if (!is_logged_in) {
      setShowTrialEnd(false);
      setShowSubscriptionCanceled(false);
      setShowPaymentIssue(false);
      return;
    }
    const isNotBillingPath = !location.pathname.includes(
      OVERVIEW_BILLING_ROUTE,
    );
    setShowTrialEnd(isNotBillingPath && isTrialEnded(subscription, account));
    setShowSubscriptionCanceled(isNotBillingPath && isCancelled(subscription, account));
    setShowPaymentIssue(isNotBillingPath && isPaymentDue(subscription, account));
  }, [subscription, location.pathname]);

  const getAppContent = () => {
    return (
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // padding: theme.spacing(2)
          }}
        >
          <React.Suspense fallback={<UbicoAppLoading />}>
            <Routes>
              <Route path="/" element={<ProtectedRoute />}>
                {renderNestedRoutes(appRoutes, profile)}
              </Route>
              <Route path="/" element={<UnprotectedRoute />}>
                {renderNestedRoutes(authRoutes)}
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </React.Suspense>
        </Box>
      </Box>
    );
  };

  const intercomUser: IntercomProps = {
    userId: `${profile?.id}`,
    email: profile?.user?.email,
    name: `${profile?.user?.first_name} ${profile?.user?.last_name}`,
    createdAt: profile?.created_at,
    company: {
      companyId: account?.id.toString(),
      name: account?.name,
      website: account?.website,
      userCount: team.length,
    },
    avatar: { type: "avatar", imageUrl: profile?.avatar },
  };

  return (
    <ErrorBoundary
      FallbackComponent={(props) => <ErrorBoundaryFallback {...props} />}
      onReset={() => {
        window.location.reload();
      }}
    >
      {isLoadingData ? (
        <UbicoLoading />
      ) : window.innerWidth <= 600 ? (
        <UnsupportedMobileView />
      ) : (
        <IntercomProvider
          appId={INTERCOM_APP_ID}
          autoBoot
          autoBootProps={intercomUser}
          shouldInitialize={REACT_APP_ENV === "prod"}
        >
          {is_logged_in && account?.completed_account_setup && <NavbarTop />}
          <NavbarLeft>{getAppContent()}</NavbarLeft>
          {invites.length > 0 && <InvitationsDialog invitations={invites} />}
          <AppSnackbar />
          <HomeNotifications />
          <TrialEndDialog open={showTrialEnd} />
          <SubscriptionCanceledDialog open={showSubscriptionCanceled} />
          <PaymentIssueDialog open={showPaymentIssue} />
        </IntercomProvider>
      )}
    </ErrorBoundary>
  );
};

export default App;
