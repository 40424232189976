import { CameraAlt, Person } from "@mui/icons-material";
import {
  Avatar,
  Box,
  ButtonProps,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Profile } from "../../../constants/data-types";
import AvatarUpload from "../../../pages/settings/account/components/profile/avatar/AvatarUpload";
import { setProfile } from "../../../redux/reducers/profileReducer";
import { RootState } from "../../../redux/store";
import ProfileService from "../../../services/profileService";

interface UbicoAvatarProps extends ButtonProps {
  allowEdit?: boolean
  selfProfile?: boolean;
  profileId?: number;
  width?: number;
  height?: number;
}

const StyledAvatar = styled(Avatar)(() => ({
  // Button style
}));

const UbicoProfileAvatar: React.FC<UbicoAvatarProps> = ({
  width = 24,
  height = 24,
  profileId = null,
  selfProfile = false,
  allowEdit = false
}) => {
  const dispatch = useDispatch()

  const team = useSelector((state: RootState) => state.team);
  const profile = team.find((p: Profile) => p.id === profileId);
  const userProfile: Profile =
    useSelector((state: RootState) => state.profile) || {};

  const [hovered, setHovered] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [imgSrc, setImgSrc] = useState(null)

  const avatarProfile = selfProfile ? userProfile : profile
  const user = avatarProfile?.user;
  const picture = avatarProfile?.avatar;


  const handleEditClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEditClose = () => {
    setAnchorEl(null);
    setHovered(null)
  };

  const openEdit = () => {
    setShowEdit(true)
    setHovered(null)
    setAnchorEl(null)
  }

  const handleRemove = async () => {
    const { error, data } = await ProfileService.updateProfileAvatar(avatarProfile?.id, new File([], ''))
    if(!error && data) dispatch(setProfile(data))
    handleEditClose()
  }

  useEffect(() => {
    const img = new Image();
    img.src = picture;

    img.onload = () => {
      setImgSrc(picture);
    };

    img.onerror = () => {
      setImgSrc(null); // Handle error case if needed
    };
  }, [picture]);

  return (
    <>
      <Box
        position="relative"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <Tooltip
          title={
            user ? user?.first_name + " " + user?.last_name : "User no longer exist"
          }
        >
          <StyledAvatar
            alt={user?.first_name + " " + user?.last_name}
            src={imgSrc}
            sx={{ width: width, height: height }}
            imgProps={{ referrerPolicy: "no-referrer" }}
            style={{
              opacity: (hovered && allowEdit) ? 0.5 : 1,
              transition: 'opacity 0.3s ease',
            }}
          >
            {user ? (
              <Typography sx={{ fontSize: width / 2 }}>
                {user?.first_name?.[0] || ""}
                {user?.last_name?.[0] || ""}
              </Typography>
            ) : (
              <Person fontSize="small" />
            )}
          </StyledAvatar>
        </Tooltip>
        <IconButton
          onClick={handleEditClick}
          size="small"
          style={{
            position: 'absolute',
            opacity: (allowEdit && hovered) ? 1 : 0,
            transition: 'opacity 0.3s ease',
          }}
        >
          <CameraAlt fontSize="small" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleEditClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem onClick={openEdit} dense>
            <Typography variant="caption">Upload New Photo</Typography>
          </MenuItem>
          <MenuItem onClick={handleRemove} dense>
            <Typography variant="caption">Remove</Typography>
          </MenuItem>
        </Menu>
      </Box>
      <AvatarUpload open={showEdit} close={() => setShowEdit(false)}/>
    </>
  );
};

export default UbicoProfileAvatar;
