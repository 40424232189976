import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { FC, ReactElement } from "react";
import TrialEndImage from "../../assets/images/dazzle-software-testing.png";
import { UBICO_SUPPORT_URL } from "../../constants/urls";
import UbicoButton from "../custom/buttons/Button";

interface SubscriptionCanceledDialogProps {
  open: boolean;
}

const SubscriptionCanceledDialog: FC<SubscriptionCanceledDialogProps> = (
  props,
): ReactElement => {
  const { open } = props;
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      sx={{
        backdropFilter: "blur(5px)",
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <DialogTitle>Your subscription was canceled</DialogTitle>
      <DialogContent>
        <Stack
          spacing={theme.spacing(2)}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box
            component="img"
            src={TrialEndImage}
            sx={{
              maxHeight: 200,
              maxWidth: 200,
              width: "auto",
              height: "auto",
              display: "block",
            }}
          />
          <Typography>
            If you want to continue using Ubico, you will need to contact us to
            subscribe again.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <UbicoButton variant="contained" href={UBICO_SUPPORT_URL}>
          Contact sales
        </UbicoButton>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionCanceledDialog;
