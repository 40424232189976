import { Add, ExpandMore, Logout } from "@mui/icons-material";
import {
  Chip,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { NestedMenuItem } from "mui-nested-menu";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UbicoLogo } from "../../../assets/logos/ubico.svg";
import { Workspace } from "../../../constants/data-types";
import { WORKSPACE_MANAGER_ROLE } from "../../../constants/user-roles";
import { setAuth } from "../../../redux/reducers/authReducer";
import { setWorkspace } from "../../../redux/reducers/workspaceReducer";
import { RootState } from "../../../redux/store";
import { HOME_ROUTE } from "../../../routes/appRoutes";
import AuthService from "../../../services/authService";
import { isUserAllowed } from "../../../utils/user-role-utils";
import UbicoProfileAvatar from "../../custom/avatars/ProfileAvatar";
import UbicoButton from "../../custom/buttons/Button";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.2),
  paddingBottom: theme.spacing(1.2),
}));

interface NavbarLeftHeaderProps {
  open: boolean;
}

const NavbarLeftHeader: React.FC<NavbarLeftHeaderProps> = (
  props,
): React.ReactElement => {
  const { open } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const profile = useSelector((state: RootState) => state.profile);
  const workspaces = useSelector(
    (state: RootState) => state.workspace.available,
  );
  const workspace =
    useSelector((state: RootState) => state.workspace.current) ?? {};

  const [isHovered, setIsHovered] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleChangeWorkspace = (id: number) => {
    dispatch(setWorkspace(workspaces?.find((workspace) => workspace.id == id)));
    setAnchorEl(null);
    navigate(HOME_ROUTE);
  };

  const openAccountSettings = () => {
    setAnchorEl(null);
    navigate("settings/account-details");
  };

  const openWorkspaceSettings = () => {
    setAnchorEl(null);
    navigate("settings/workspace-details");
  };

  const openWorkspaces = () => {
    setAnchorEl(null);
    navigate("settings/workspaces");
  };

  const openProfileSettings = () => {
    setAnchorEl(null);
    navigate("settings/profile-details");
  };

  const openMembers = () => {
    setAnchorEl(null);
    navigate("settings/team-members");
  };

  const logout = async () => {
    dispatch(setAuth({ is_logged_in: false }));
    await AuthService.logout();
    navigate("login");
  };

  return (
    <Box
      p={theme.spacing(1)}
      display={"flex"}
      justifyContent={open ? "flex-start" : "center"}
    >
      <Stack spacing={theme.spacing(0)} alignItems={"center"} width={"100%"}>
        <UbicoButton
          onClick={handleMenuClick}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
          color="inherit"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          sx={{
            background: "transparent",
            "&:hover": {
              background: "transparent",
            },
            width: open ? "100%" : "fit-content",
            minWidth: "auto",
          }}
        >
          <Stack
            direction={"row"}
            spacing={theme.spacing(2)}
            width={"100%"}
            alignItems={"center"}
          >
            <Box
              pt={theme.spacing(1)}
              pb={theme.spacing(1)}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <SvgIcon
                component={UbicoLogo}
                sx={{
                  color: theme.palette.text.primary,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                inheritViewBox
              />
            </Box>
            {open && (
              <Stack
                justifyContent={"flex-start"}
                width={"100%"}
                overflow={"hidden"}
              >
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  width={"100%"}
                  overflow={"hidden"}
                >
                  <Typography
                    textOverflow={"ellipsis"}
                    noWrap
                    minWidth={"80%"}
                    textTransform={"initial"}
                    textAlign={"left"}
                    variant="body1"
                    fontWeight={"bolder"}
                  >
                    {workspace?.name ? workspace.name : <Skeleton />}
                  </Typography>
                  <ExpandMore fontSize="small" />
                </Stack>
                <Typography
                  textAlign={"left"}
                  variant="body2"
                  textTransform={"capitalize"}
                  color={
                    isHovered || openMenu
                      ? theme.palette.text.primary
                      : theme.palette.text.disabled
                  }
                  sx={{ transition: "color 0.15s linear 0s" }}
                  textOverflow={"ellipsis"}
                  noWrap
                >
                  {`${profile.user.first_name} ${profile.user.last_name}`}
                </Typography>
              </Stack>
            )}
          </Stack>
        </UbicoButton>
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleMenuClose}
          PaperProps={{ sx: { minWidth: 200 } }}
        >
          <Stack>
            {isUserAllowed(profile?.user_role, WORKSPACE_MANAGER_ROLE) && (
              <>
                <StyledMenuItem dense onClick={openAccountSettings}>
                  Account settings
                </StyledMenuItem>
                <StyledMenuItem dense onClick={openWorkspaceSettings}>
                  Workspace settings
                </StyledMenuItem>
                <StyledMenuItem dense onClick={openMembers}>
                  Members
                </StyledMenuItem>
              </>
            )}
          </Stack>
          <NestedMenuItem
            dense
            parentMenuOpen={openMenu}
            label={"Switch workspace"}
            sx={{
              ".MuiTypography-root": { fontSize: 14 },
              pl: theme.spacing(1),
              pb: theme.spacing(1.2),
              pt: theme.spacing(1.2),
              mb: theme.spacing(1),
            }}
            MenuProps={{
              MenuListProps: { sx: { p: 0 } },
              PaperProps: { sx: { minWidth: 250 } },
            }}
          >
            {workspaces.map((wsp: Workspace) => (
              <StyledMenuItem
                key={wsp.id}
                dense
                onClick={() => handleChangeWorkspace(wsp.id)}
                selected={workspace?.id === wsp?.id}
              >
                <Stack
                  alignItems={"center"}
                  width={"100%"}
                  direction={"row"}
                  justifyContent={
                    wsp?.is_default ? "space-between" : "flex-end"
                  }
                >
                  {wsp?.is_default && (
                    <Chip
                      variant="outlined"
                      size="small"
                      color={"primary"}
                      label="Default"
                    />
                  )}
                  <Typography variant="body2">{wsp.name}</Typography>
                </Stack>
              </StyledMenuItem>
            ))}
            <StyledMenuItem
              sx={{ bgcolor: theme.palette.background.paper }}
              dense
              onClick={openWorkspaces}
            >
              <ListItemIcon>
                <Add fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create workspace</ListItemText>
            </StyledMenuItem>
          </NestedMenuItem>
          <Divider />

          <StyledMenuItem dense onClick={openProfileSettings}>
            <Stack
              width={"100%"}
              direction={"row"}
              alignItems={"center"}
              spacing={theme.spacing(2)}
            >
              <UbicoProfileAvatar profileId={profile?.id} />
              <Stack overflow={"hidden"} maxWidth={150}>
                <Typography
                  textOverflow={"ellipsis"}
                  noWrap
                  variant="subtitle2"
                  fontWeight={"bolder"}
                >
                  {`${profile.user.first_name} ${profile.user.last_name}`}
                </Typography>
                <Typography textOverflow={"ellipsis"} noWrap variant="caption">
                  {profile.user.email}
                </Typography>
              </Stack>
            </Stack>
          </StyledMenuItem>
          <StyledMenuItem dense onClick={openProfileSettings}>
            Profile settings
          </StyledMenuItem>
          <StyledMenuItem dense onClick={logout}>
            <ListItemText>Sign out</ListItemText>
            <ListItemIcon sx={{ minWidth: "0px !important" }}>
              <Logout fontSize="small" />
            </ListItemIcon>
          </StyledMenuItem>
        </Menu>
      </Stack>
    </Box>
  );
};

export default NavbarLeftHeader;
