import { AxiosService, UbicoAPIResponse } from "../api-provider";
import {
  Account,
  AccountUsage,
  CreditUsage,
  Profile,
  StripePaginatedData,
  UbicoBilling,
  UbicoBillingDetails,
  UbicoCustomer,
  UbicoInvoice,
  UbicoPaymentIntent,
  UbicoProduct,
  UbicoSubscription,
} from "../constants/data-types";
import { API_PATH } from "../constants/paths";
import { GENERIC_ERROR_MESSAGE } from "../constants/snackbar-messages";
import { SegmentStatsFrequencies } from "../pages/overview/constants/segment-stats";
import {
  CancellationFlowFeedbacks,
  CreditUsageType,
} from "../pages/settings/account/constants/account-billing";

const requestFeature = async (
  featureName: string
): Promise<UbicoAPIResponse<any>> => {
  try {
    const resp = await AxiosService.post(
      `${API_PATH}/account/request-feature-access/`,
      {
        feature_name: featureName,
      }
    );
    return { error: false, data: resp.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateAccount = async (
  account_id: number,
  data: Account
): Promise<UbicoAPIResponse<Account>> => {
  try {
    const account = await AxiosService.put(
      `${API_PATH}/account/${account_id}/`,
      data
    );
    return { error: false, data: account.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getAccount = async (): Promise<UbicoAPIResponse<Account>> => {
  try {
    const account = await AxiosService.get(`${API_PATH}/account/`);
    return { error: false, data: account.data[0], message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getAccountOverview = async (): Promise<
  UbicoAPIResponse<AccountUsage>
> => {
  try {
    const account = await AxiosService.get(`${API_PATH}/account/usage/`);
    return { error: false, data: account.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getTeam = async (): Promise<UbicoAPIResponse<Array<Profile>>> => {
  try {
    const profiles = await AxiosService.get(`${API_PATH}/account/profiles/`);
    return { error: false, data: profiles.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getProducts = async (): Promise<UbicoAPIResponse<UbicoProduct[]>> => {
  try {
    const products = await AxiosService.get(
      `${API_PATH}/account/billing/products/`
    );
    return { error: false, data: products.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getSubscriptionDetails = async (): Promise<
  UbicoAPIResponse<UbicoSubscription>
> => {
  try {
    const subscription = await AxiosService.get(
      `${API_PATH}/account/billing/subscription/`
    );
    return { error: false, data: subscription.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const cancelSubscription = async (
  feedback: CancellationFlowFeedbacks,
  comment: string
): Promise<UbicoAPIResponse<UbicoSubscription>> => {
  try {
    const subscription = await AxiosService.post(
      `${API_PATH}/account/billing/subscription/cancel/`,
      { feedback, comment }
    );
    return { error: false, data: subscription.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.details ||
        error.response?.data?.error ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const applySubscriptionCoupon = async (): Promise<
  UbicoAPIResponse<UbicoSubscription>
> => {
  try {
    const subscription = await AxiosService.post(
      `${API_PATH}/account/billing/subscription/apply-retention-coupon/`
    );
    return { error: false, data: subscription.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.details ||
        error.response?.data?.error ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const getPaymentIntent = async (): Promise<
  UbicoAPIResponse<UbicoPaymentIntent>
> => {
  try {
    const payment_intent = await AxiosService.get(
      `${API_PATH}/account/billing/setup-payment/`
    );
    return { error: false, data: payment_intent.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getPreviewInvoice = async (
  plan_si_id: string,
  plan_price_id: string,
  credit_si_id: string,
  credit_price_id: string,
  plan_quantity: number
): Promise<UbicoAPIResponse<UbicoInvoice>> => {
  try {
    const payment_intent = await AxiosService.post(
      `${API_PATH}/account/billing/preview-invoice/`,
      {
        plan_si_id,
        plan_price_id,
        credit_si_id,
        credit_price_id,
        plan_quantity,
      }
    );
    return { error: false, data: payment_intent.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateBillingAddress = async (
  details: UbicoBillingDetails
): Promise<UbicoAPIResponse<UbicoCustomer>> => {
  try {
    const payment_intent = await AxiosService.post(
      `${API_PATH}/account/billing/update-details/`,
      details
    );
    return { error: false, data: payment_intent.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getInvoices = async (
  starting_after?: string,
  ending_before?: string
): Promise<UbicoAPIResponse<StripePaginatedData<UbicoInvoice>>> => {
  try {
    const params = {};
    if (starting_after) params["starting_after"] = starting_after;
    else if (ending_before) params["ending_before"] = ending_before;

    const invoices = await AxiosService.get(
      `${API_PATH}/account/billing/invoices/`,
      { params: params }
    );
    return { error: false, data: invoices.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const updateSubscription = async (
  billing: UbicoBilling
): Promise<UbicoAPIResponse<UbicoSubscription>> => {
  try {
    const subscription = await AxiosService.post(
      `${API_PATH}/account/billing/update-subscription/`,
      billing
    );
    return { error: false, data: subscription.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error ||
        error?.response?.data?.detail ||
        error.message ||
        GENERIC_ERROR_MESSAGE,
    };
  }
};

const disableTeamMember = async (
  disable_profile_id: number,
  new_owner_id: number
): Promise<UbicoAPIResponse<null>> => {
  try {
    await AxiosService.post(
      `${API_PATH}/account/profiles/${disable_profile_id}/disable/`,
      {
        new_owner_id,
      }
    );
    return { error: false, data: null, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const getCreditUsage = async (
  start_date: string,
  end_date: string,
  usage_type?: CreditUsageType,
  cumulative = true,
  frequency = SegmentStatsFrequencies.Weekly
): Promise<UbicoAPIResponse<CreditUsage[]>> => {
  try {
    const data = { cumulative };
    if (usage_type >= 0) data["usage_type"] = usage_type;
    if (start_date && end_date) {
      data["start_date"] = start_date;
      data["end_date"] = end_date;
    }
    if (frequency) data["frequency"] = frequency;
    const subscription = await AxiosService.post(
      `${API_PATH}/account/credit-usage/`,
      data
    );
    return { error: false, data: subscription.data, message: "" };
  } catch (error: any) {
    return {
      error: true,
      message:
        error.response?.data?.error || error.message || GENERIC_ERROR_MESSAGE,
    };
  }
};

const AccountService = {
  updateAccount,
  getAccount,
  getAccountOverview,
  cancelSubscription,
  applySubscriptionCoupon,
  getPreviewInvoice,
  updateSubscription,
  updateBillingAddress,
  getTeam,
  getInvoices,
  getProducts,
  getSubscriptionDetails,
  getPaymentIntent,
  disableTeamMember,
  getCreditUsage,
  requestFeature,
};

export default AccountService;
